import React from 'react';
import './style.scss';
import ImgTech from '../../../../images/tech.png';


const SprintSection = () => {
  return (
    <section id="Sprint">
      <div className="container">
        <div className="row">
          <div className="Sprint">
            <div className="grid-2 title">
              <div className="left section-title">SPRINT METHODS</div>
              <div className="right">
                프로젝트 진행은 스프린트 단위로 진행되며, 스프린트는 [계획 - 개발 - 리뷰 - 회고]의 4단계로 구성됩니다.
              </div>
            </div>
            <div className="grid-2 sprint-item">
              <div className="left">
                <div className="emoji">📄</div>
                <div className="title">Planning</div>
                <div className="content">계획</div>
              </div>
              <div className="right">
                멤버들은 이슈 백로그를 기반으로 스코어게임을 수행하여 업무분량을 공동산출하며, 공동산출된 업무를 분담하여 각자 개발을 합니다.
              </div>
            </div>
            <div className="grid-2 sprint-item">
              <div className="left">
                <div className="emoji">💻</div>
                <div className="title">Development</div>
                <div className="content">개발</div>
              </div>
              <div className="right">
              각자 맡은 업무에 대해 개발을 수행하며, 기간 중 데일리 미팅을 통해 서로 해결하기 어려운 이슈에 대한 
토의를 통해 문제를 해결해 나갑니다.
              </div>
            </div>
            <div className="grid-2 sprint-item">
              <div className="left">
                <div className="emoji">👓</div>
                <div className="title">Review</div>
                <div className="content">리뷰</div>
              </div>
              <div className="right">
              결과물의 데모와 소스코드 리뷰를 함께 진행합니다. 이를 통해 진보된 코드에 대한 상호학습과 부족한 
코드에 대한 개선이 이루어집니다. 개선된 코드는 Github의 Pull Request를 통해 마지막 검토를 받으며 SCM담당자의 최종 Merge를 통해 작업이 완료됩니다.
              </div>
            </div>
            <div className="grid-2 sprint-item">
              <div className="left">
                <div className="emoji">✨</div>
                <div className="title">Retrospect</div>
                <div className="content">회고</div>
              </div>
              <div className="right">
              완료한 스프린트에 대해 조직적, 행정적, 개인적 문제점을 공유하고 개선에 대한 계획과 담당자를 수립하고 이후 리뷰까지 개선을 위해 상호 노력합니다. 또한 잘 이루어진 부분이나 좋았던 부분은 지속 유지를 통해 프로세스를 안정시켜나갑니다.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SprintSection;
