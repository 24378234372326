import React from 'react';
import './style.scss';
import ImgSolution from '../../../../images/solution0.png';
import ImgSolution1 from '../../../../images/solution1.png';
import ImgSolution2 from '../../../../images/solution2.png';
import IconArrow from '../../../../images/icon-arrow.svg';


const SolutionSection = () => {
  return (
    <section id="Solution">
      <div className="container">
        <div className="row">
          <div className="Solution">
            <div className="wrap cvve">
              <div className="grid-2 cvve">
                  <div className="section-title">DGM SOLUTION</div>
                  <div className="left">
                    <div className="title">CVVE 클라우드 서비스 </div>
                    <div className="description">
                      다양한 업종의 가맹사업자와 중소사업자를 위해 초기 구축비용과 유지보수비용을 최소화한
                      클라우드 기반의 솔루션을 제공합니다.
                    </div>
                    <div className="btn">
                      <a 
                        href="http://www.dgmunit1.com/" 
                        title="unit1 & cvve solution 홈페이지" 
                        target="_blank">
                        <button>CVVE 자세히 보기 <img src={IconArrow} alt="arrow-icon" /></button>
                      </a>
                    </div>
                  </div>
                  <div className="right">
                    <img src={ImgSolution} alt="solution" />
                  </div>
              </div>
            </div>
            <div className="wrap">
              <div className="grid-2 provider">

                  <div className="right">
                    <img src={ImgSolution1} alt="solution2" />
                  </div>
                  <div className="left">
                    <div className="title">솔루션 프로바이더 </div>
                    <div className="description">
                      기업의 IT 부서 전체 
                      혹은 일부 기능적 업무를 위탁 수행합니다.
                    </div>
                  </div>
              </div>
            </div>
            <div className="wrap">
              <div className="grid-2 etc">
                  <div className="left">
                    <div className="title">기타 개발 사례 O2O 서비스</div>
                    <div className="description">
                      다양한 업종의 가맹사업자와 중소사업자를 위해 초기 구축비용과 유지보수비용을 최소화한
                      클라우드 기반의 솔루션을 제공합니다.
                    </div>
                  </div>
                  <div className="right">
                    <img src={ImgSolution2} alt="solution3" />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SolutionSection;
