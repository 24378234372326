import React from 'react';
import './style.scss';
import LogoImg from '../../../../images/logo/horizontal.png';
import ImgValue from '../../../../images/mvvValue.png'

const MvvSection = () => {
  return (
    <section id="Mvv">
      <div className="container">
        <div className="row">
          <div className="section-title">DGM MVV</div>
          <div className="Mvv">
            <div className="mvvitem">
              <div className="top">
                <div className="emoji">⭐️</div>
                <div className="description">Donation & Gathering can Make IT</div>
                <div className="content">기부와 수확을 통해 꿈을 성취하는 기업</div>
              </div>
              <div className="bottom">MISSON</div>
            </div>
            <div className="mvvitem">
              <div className="top">
                <div className="emoji">👍</div>
                <div className="description">We make the first and the best.</div>
                <div className="content">우리는 최초를 만들고 최고가 된다</div>
              </div>
              <div className="bottom">VISION</div>
            </div>
            <div className="mvvitem value">
              <div className="top">
                <img src={ImgValue} alt="mvv value" />
              </div>
              <div className="bottom">VALUE</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MvvSection;
