import React from 'react';
import Seo from '../components/common/Seo';
import CommentSection from '../components/Home/sections/CommentSection';
import CvveLoungeSection from '../components/Home/sections/CvveLoungeSection';
import MvvSection from '../components/Home/sections/MvvSection';
import RecruitSection from '../components/Home/sections/RecruitSection';
import SolutionSection from '../components/Home/sections/SolutionSection';
import SprintSection from '../components/Home/sections/SprintSection';
import TechSection from '../components/Home/sections/TechSection';
import UsSection from '../components/Home/sections/UsSection';
import Layout from '../layouts/Layout';

const Home = () => {
  return (
    <Layout>
      <UsSection />
      <MvvSection />
      <SprintSection />
      <TechSection />
      <SolutionSection />
      <CvveLoungeSection />
      <RecruitSection />
      <CommentSection />
    </Layout>
  );
};

export default Home;

export const Head = () => {
  return <Seo />;
};
