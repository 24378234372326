import React from 'react';
import './style.scss';
import ImgTech from '../../../../images/tech.png';
import ImgTechM from '../../../../images/tech-m.png';


const TechSection = () => {
  return (
    <section id="Tech">
      <div className="container">
        <div className="row">
          <div className="Tech">
            <div className="section-title">TECHNOLOGY STACKS</div>
            <img id="tech-img" src={ImgTech} alt="기술스텍" />
            <img id="tech-m-img" src={ImgTechM} alt="기술스텍" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechSection;
