import React from 'react';
import './style.scss';
import ImgBubble from '../../../../images/bubble.svg';


const CommentSection = () => {
  return (
    <section id="Comment">
      <div className="container">
        <div className="row">
          <div className="Comment">
            <div className="section-title">DGM멤버들의 한마디</div>
            <div className="grid-2 bubble">
              <div className="left"> </div>
              <div className="right imgRotate">
                <div className="text textRotate">
                  유연하게 사고하고 함께 발 맞춰 일할 수 있는 조직
                </div>
              </div>
              <div className="left emoji">
                😋
              </div>
            </div>
            <div className="grid-2 bubble">
              <div className="left">
                😊
              </div>
              <div className="right">
                <div className="text">
                  발전할 수 있는 곳, 다양한 언어를 접하고 멤버들과 함께 배울 수 있습니다.
                </div>
              </div>
              <div className="left"> </div>
            </div>
            <div className="grid-2 bubble">
              <div className="left"> </div>
              <div className="right imgRotate">
                <div className="text textRotate">
                  업무 성취과 자기 계발, 자아 실현과 보상에 대한 만족 모두 우수한 회사입니다.<br/>
                  무엇보다 에자일 조직문화가 잘 만들어진 회사입니다.
                </div>
              </div>
              <div className="left emoji">
                🙂
              </div>
            </div>
            <div className="grid-2 bubble">
              <div className="left">
                🙌
              </div>
              <div className="right">
                <div className="text">
                  우리의 WHITE PAPER를 더 좋은 회사문화와 복리후생으로 함께 만들어가고 채워 갈 팀멤버를 찾습니다~
                </div>
              </div>
              <div className="left"> </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommentSection;
