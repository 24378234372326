import React from 'react';
import './style.scss';
import ImgWelfare from '../../../../images/welfare.png';


const RecruitSection = () => {
  return (
    <section id="Recruit">
      <div className="container">
        <div className="row">
          <div className="Recruit">
            <div className="section-title">DGM RECRUIT</div>
            <div className="grid-2">
              <div className="left title">
                DGM 담당업무
              </div>
              <div className="right">
                <p>
                  DGMIT UNIT#1은 기획, 설계, 프론트, 백엔트, 운영에 이르기 까지, 모든 팀 멤버들이 넓은 스코프를 갖는 것을 목표로 합니다.
                </p>
                <div className="grid-2">
                  <div className="left">
                    <div className="box">
                      <p className="sub-title"><b>1단계</b> 멤버 경험을 기반으로 중심 업무시작 및 역량 확장</p>
                      <p>
                      ⚙️ 백앤드개발 배경<br/>
                      JAVA/SPRING/MYBATIS 혹은 PHP, ASP 경험자<br/>
                      👉 서버 API 개발 및 관리자(Backoffice) 개발을 중심으로 Javascript 학습을 통한 프론트앤드 개발자로 성장<br/>
                      <br/>
                      💻 프론트앤드개발 배경  <br/>
                      Javascript framework (React, Vue, React-native, Flutter, Angular 등) 경험자 <br/>
                      👉 Database 및 Server 파트 학습을 통한 API 이해 및 개발, 관리자(Backoffice) 개발자로 성장<br/>
                      <br/>
                      🎨 웹디자인/퍼브리셔 배경  <br/>
                      Photoshop/Illust/jQuery/HTML5/CSS3 경험자<br/>
                      👉 Javascript 심화학습을 통한 프론트앤드개발자로 성장 혹은 UI 및 UX 하위 기획 및 프로토타이핑 분야로 성장
                      </p>
                    </div>
                  </div>
                  <div className="right">
                    <div className="box">
                      <p className="sub-title"><b>2단계</b> 공통 학습을 통한 풀스택 지식역량 확대</p>
                      <p>
                      1. 업무분석, 서비스 기획 및 기능정의, 하위기획에 대한 사항<br/>
                      2. 데이터베이스 설계 및 구축에 대한 사항<br/>
                      3. 서버운용에 대한 기본 사항<br/>
                      </p>
                    </div>
                    <div className="box">
                      <p className="sub-title"><b>3단계</b> 본인 직무적성에 따른 심화 업무로의 중심 업무 이동</p>
                      <p>
                      1. 상위 기획 및 설계자로의 로드맵<br/>
                      2. 하위기획 및 프론트앤드 개발자로의 로드맵<br/>
                      3. 프론트앤드 및 백엔드 개발자로의 로드맵<br/>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="common">
                  <p><br/>공통</p>
                  <p>
                  <br/>
                  DGM의 모든 멤버는 개발을 할 수 있는 역량을 기본으로 합니다. <br/>
                  기획자나 디자이너나 설계자나 프론트앤드 개발자나 백앤드 개발자나 모두 심화 및 중심업무와 함께 개발을 수행합니다.<br/><br/>

                  3단계 제1직무를 대상으로 본인 희망여부에 따라 설계와 개발 역량을 기반으로 상위 기획과 컨설팅 업무로 성장 시키는 것을 목표로 하며,  <br/>
                  3단계 제2, 3직무를 대상으로는 1단계 부터 지속적인 성장을 통해 전문 개발자로의 역량으로 성장 시키는 것을 목표로 합니다.<br/>
                  </p>
                </div>
              </div>
            </div>
            <div className="Welfare">
              <div className="subtitle">당연한거라 안 적었었지만,, 인재를 꼬시기위한 그런,, 👉👈</div>
              <div className="title">멤버들과 함께 만들어가는 DGM의 복리후생</div>
              <ul>
                <li>Early Weekend</li>
                <li>맥북프로 및 장비지원</li>
                <li>자유로운 연차</li>
                <li>멤버생일선물🎁</li>
                <li>명절선물🎁</li>
                <li>어버이날선물🎁</li>
                <li>크리스마스선물🎁</li>
                <li>자유로운 근무환경</li>
                <li>랜선회식 배민상품권 발송🚙</li>
                <li>우수사원포상</li>
                <li>월 1회 커피원두제공☕️</li>
                <li>후원기부금 지원</li>
              </ul>
              <div className="grid-2">
                <div className="left">
                  <img src={ImgWelfare} alt="성과공유기업" />
                </div>
                <div className="right">
                  <div className="subtitle">함께 성장을 위한</div>
                  <div className="title">중소기업 성과공유 협약서 </div>
                  <div className="description dc1">
                    (경영성과급) 목표 달성시 사내규정에 따라 성과급을 상여금 또는 우리사주로 지급한다.
                    회계 결산 이익금의 50%를 시용기간을 제외한 정규직 구성원 인원 수로 나누어 배분한다.
                  </div>
                  <div className="description dc2">
                    연간 회사의 수익의 50%는 조직 발전을 위해 사용되고, 
                    나머지 50%는 멤버들 인원 수로 나눠 성과급으로 공유한다고 얘기 해 왔습니다. 
                    우리는 이야기에 그치지않고 중소기업 성과공유 협약서를 정식 채결하였고 배분하고 있습니다.
                  </div>

                  <div className="title">청년내일채움공제  </div>
                  <div className="description dc1">
                    (내일채움공제) 성과공유를 위해 내일채움공제를 도입한다.
                    (청년내일채움공제) 성과공유를 위해 청년내일채움공제를 도입한다.
                    (청년재직자내일채움공제) 성과공유를 위해 청년재직자내일채움공제를 도입한다.
                  </div>
                  <div className="description dc2">
                    중소기업 성과공유 협약서에 청년내일채움공제를 도입한다는 내용도 함께 작성되어있고,
                    멤버들이 함께 내일채움공제 또는 청년재직자내일채움공제를 진행 중 입니다.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RecruitSection;
