import React from 'react';
import './style.scss';
import LogoImg from '../../../../images/logo/DGMIT_LOGO.svg';


const UsSection = () => {
  return (
    <section id="Us">
      <div className="container">
        <div className="row">
          <div className="Us">
            <div className="grid-2">
              <div className="left">
                <h1>우리는</h1>
                <ul>
                  <li>👫 수평적 문화를 추구합니다</li>
                  <li>📈 상향 평준화를 위해 노력합니다</li>
                  <li>🏆 멤버의 성공을 최우선으로 목표합니다</li>
                  <li>💻 방향을 논의하고 함께 개발해 나갑니다</li>
                  <li>🤝 성과를 공유합니다</li>
                </ul>
              </div>
              <div className="right">
                <img src={LogoImg} alt="dgmLogo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UsSection;
