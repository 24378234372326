import React from 'react';
import './style.scss';
import ImgLounge from '../../../../images/cvveLounge.png';
import ImgLoungeLogo from '../../../../images/logo/cvveLoungeLogo.png';
import ImgPDF from '../../../../images/icon-pdf.png';
import FilePdf from '../../../../../static/DGMIT_2022.pdf';

const CvveLoungeSection = () => {
  return (
    <section id="CvveLounge">
      <div className="container">
        <div className="row">
          <div className="CvveLounge">
            <div className="section-title">
              <img src={ImgLoungeLogo} alt="cvve-lounge-logo" />
              <p>CVVE Lounge를 운영중입니다.</p>
            </div>
            <img src={ImgLounge} alt="cvve-lounge이미지" />
          </div>
          <div className="pdf-link" id="RecruitPdf">
            <a href={FilePdf} download>
              <img src={ImgPDF} alt="회사소개 pdf" />
              <em>DGM_2022.pdf</em>
            </a>
            <p>더 자세한 DGM의 BUSINESS는 PDF파일을 확인 해 주세요</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CvveLoungeSection;
